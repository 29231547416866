function createObject() {
  let example = {
    id: "LBST_ZV_Pfade_DV",
    workOrder: `Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.`,
    image: require("@/assets/zv/Pfade/darstellungsvernetzung/01.png"),
    imageSizePercent: 60,
    mediaDescription: "Mira bearbeitet die folgende Aufgabe:",
    pupilName: "Mira",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question:
            "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/02.png"),
              solutionImage: require("@/assets/zv/Pfade/darstellungsvernetzung/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/04.png"),
              solutionImage: require("@/assets/zv/Pfade/darstellungsvernetzung/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/06.png"),
              solutionImage: require("@/assets/zv/Pfade/darstellungsvernetzung/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/08.png"),
              solutionImage: require("@/assets/zv/Pfade/darstellungsvernetzung/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/zv/Pfade/darstellungsvernetzung/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 =
    "Diagnoseaufgabe 1 zeigt, dass Mira den Aufbau und die Strukturierung von Würfelbildern nicht verinnerlicht hat. Gleichzeitig wird jedoch deutlich, dass ihre kardinale Zahlvorstellung ausgeprägt ist, da die Anzahl der Augen der zugeordneten Seite korrekt ist. ";

  const DA_2 =
    "Diagnoseaufgabe 2 lässt erkennen, dass Mira die kardinal dargestellte Plättchenanzahl nicht korrekt in die ordinale Darstellungsweise am Zahlenstrahl eintragen kann. Ein möglicher Grund dafür könnte sein, dass sie die Anzahl der Einheitsstriche abgezählt hat, weswegen ihre eingetragene Zahl um 1 zu klein ist. ";

  const DA_3 =
    "Diagnoseaufgabe 3 zeigt zum einen, dass Mira die Zahl nicht korrekt vom Zahlenstrahl entnehmen kann. Grund dafür könnte sein, dass sie die Anzahl der Einheitsstriche abgezählt hat. Zum anderen zeigt sich, dass Miras unmittelbare Vorstellung der Zahl keine ordinale oder kardinale Struktur aufweist. ";

  const DA_4 =
    "Diagnoseaufgabe 4 lässt erkennen, dass Mira Zahlzerlegungen erkennen und im Sinne der Teil-Ganzes-Beziehung die zwei Zerlegungsteile jeweils zu einem Ganzen zusammenfügen kann. ";

  const FA_1 =
    "Förderaufgabe 1 spricht vordergründig ein Verständnis für die Würfeldarstellungen an. Durch die vorgegebenen Würfeldarstellungen lernt sie diese Darstellungsweise kennen. Im Sinne der Teil-Ganzes-Beziehung muss sie in einem nächsten Schritt eine mögliche Zerlegung der Zahl 9 kennzeichnen. ";

  const FA_2 =
    "Förderaufgabe 2 versucht, Miras Verständnis für ordinale Beziehungen am Zahlenstrahl zu fördern, indem die 5 als Nachbarzahl der einzuzeichnenden Zahl vorgegeben wird. Durch die strukturierte Darstellung, bei der nun auch die ‚Kraft der Fünf‘ erkennbar ist, soll ihr die Struktur des Zahlenstrahls bewusst werden. In einem zweiten Schritt soll sie nun Vorgänger und Nachfolger bei anderen Zahlen erfassen.  ";

  const FA_3 =
    "Förderaufgabe 3 spricht erneut die Zahlzerlegungen an. Vor dem Hintergrund der Diagnose ist jedoch anzunehmen, dass Mira das Prinzip der Zahlzerlegung im Sinne der Teil-Ganzes-Beziehung verstanden hat. ";

  const FA_4 =
    "Förderaufgabe 4 vertieft Miras Verständnis für Würfeldarstellungen. Durch die vorgegebenen Würfeldarstellungen lernt sie diese Darstellungsweise kennen. Mithilfe dieser soll Mira nun selbstständig verschiedene Würfelbilder der Zahl 7 erstellen. Hierbei kommt ihr Verständnis für die Teil-Ganzes-Beziehung zum Einsatz. ";

  const PASSUNG_SEHR_GUT = `
  Diese Förderaufgabe passt sehr gut zur Diagnose.<br />`;
  const PASSUNG_GUT = `
  Diese Förderaufgabe passt ganz gut zur Diagnose.<br />`;
  const PASSUNG_SCHLECHT = `
  Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.<br />`;
  const PASSUNG_SEHR_SCHLECHT = `
  Diese Aufgabe hätten wir nicht gewählt.<br />`;
  const ZAHLASPEKT =
    "Die Förderaufgabe fokussiert somit einen anderen Zahlaspekt als die gewählten Diagnoseaufgaben. ";

  example.responses = [
    {
      id: "112",
      text: PASSUNG_GUT + DA_1 + DA_2 + FA_1,
    },
    {
      id: "113",
      text: PASSUNG_GUT + DA_1 + DA_3 + FA_1,
    },
    {
      id: "114",
      text:
        PASSUNG_SEHR_GUT +
        DA_1 +
        DA_4 +
        FA_1 +
        "Da sie ein Verständnis für die Teil-Ganzes-Beziehung und kardinale Zahldarstellungen zu haben scheint, wird hier das Verständnis für die Würfeldarstellungen fokussiert. ",
    },
    {
      id: "123",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_1 + ZAHLASPEKT,
    },
    {
      id: "124",
      text:
        PASSUNG_SEHR_SCHLECHT +
        DA_2 +
        DA_4 +
        FA_1 +
        "Miras Verständnis für Zahlzerlegungen scheint ausgeprägt zu sein. ",
    },
    {
      id: "134",
      text:
        PASSUNG_SEHR_SCHLECHT +
        DA_3 +
        DA_4 +
        FA_1 +
        "Miras Verständnis für Zahlzerlegungen scheint ausgeprägt zu sein. ",
    },
    {
      id: "212",
      text: PASSUNG_GUT + DA_1 + DA_2 + FA_2,
    },
    {
      id: "213",
      text: PASSUNG_GUT + DA_1 + DA_3 + FA_2,
    },
    {
      id: "214",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_2 + ZAHLASPEKT,
    },
    {
      id: "223",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_2,
    },
    {
      id: "224",
      text: PASSUNG_GUT + DA_2 + DA_4 + FA_2,
    },
    {
      id: "234",
      text: PASSUNG_GUT + DA_3 + DA_4 + FA_2,
    },
    {
      id: "312",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_3,
    },
    {
      id: "313",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_3,
    },
    {
      id: "314",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_3,
    },
    {
      id: "323",
      text: PASSUNG_SCHLECHT + DA_2 + DA_3 + FA_3,
    },
    {
      id: "324",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_3,
    },
    {
      id: "334",
      text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_3,
    },
    {
      id: "412",
      text: PASSUNG_GUT + DA_1 + DA_2 + FA_4,
    },
    {
      id: "413",
      text: PASSUNG_GUT + DA_1 + DA_3 + FA_4,
    },
    {
      id: "414",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_4,
    },
    {
      id: "423",
      text: PASSUNG_GUT + DA_2 + DA_3 + FA_4,
    },
    {
      id: "424",
      text: PASSUNG_GUT + DA_2 + DA_4 + FA_4,
    },
    {
      id: "434",
      text: PASSUNG_GUT + DA_3 + DA_4 + FA_4,
    },
  ];

  return example;
}

export default createObject();
