function createObject(){
    let example = {
        id: "LBST_ZV_Gruppen_Zaehlfehler",
        showGroupNames: false,
        checkAfterHowManyItems: 3,
        checkAfterExactItemCount: true,
        workOrder: `Teilen sie die vorliegenden Schülerdokumente in Kategorien typischer Zählfehler ein.`,
        possibleCategories: [
            {
                id: 1,
                name: "Übergeneralisierung zuerst versprachlichter Einer"
            },
            {
                id: 2,
                name: "Übergeneralisierung nach Stellenübergang"
            },
            {
                id: 3,
                name: "Übergeneralisierung der Zehnerbildung mit -zig"
            },
        ],
        elements: [
            {
                id: 3,
                image: require("@/assets/zv/Gruppen/zaehlfehler/03.png"),
                category: 1,
                flex: 3,
            },
            {
                id: 4,
                image: require("@/assets/zv/Gruppen/zaehlfehler/04.png"),
                category: 2,
                flex: 3,
            },
            {
                id: 2,
                image: require("@/assets/zv/Gruppen/zaehlfehler/02.png"),
                category: 1,
                flex: 3,
            },
            {
                id: 7,
                image: require("@/assets/zv/Gruppen/zaehlfehler/07.png"),
                category: 3,
                flex: 3,
            },
            {
                id: 8,
                image: require("@/assets/zv/Gruppen/zaehlfehler/08.png"),
                category: 3,
                flex: 3,
            },
            {
                id: 5,
                image: require("@/assets/zv/Gruppen/zaehlfehler/05.png"),
                category: 2,
                flex: 3,
            },
            {
                id: 6,
                image: require("@/assets/zv/Gruppen/zaehlfehler/06.png"),
                category: 2,
                flex: 3,
            },
            {
                id: 9,
                image: require("@/assets/zv/Gruppen/zaehlfehler/09.png"),
                category: 3,
                flex: 3,
            },
            {
                id: 1,
                image: require("@/assets/zv/Gruppen/zaehlfehler/01.png"),
                category: 1,
                flex: 3,
            },
        ]
    };

    const einsEiner = "In einem der Beispiele wurde der Einer fälschlicherweise vor dem Zehner realisiert. ";

    const zweiEiner = "In zweien der Beispiele wurde der Einer fälschlicherweise vor dem Zehner realisiert. ";

    const einsStelle = "In einem der Beispiele wurde die Zahlwortbildung trotz Stellenwertübergangs analog fortgesetzt, was zu falschen Zahlwörtern führt. ";

    const zweiStelle = "In zweien der Beispiele wurde die Zahlwortbildung trotz Stellenwertübergangs analog fortgesetzt, was zu falschen Zahlwörtern führt. ";

    const einsZehner = "In einem der Beispiele wurde die Zehnerbildung fälschlicherweise mit –zig realisiert. ";

    const zweiZehner = "In zweien der Beispiele wurde die Zehnerbildung fälschlicherweise mit –zig realisiert. ";

    const erneut = "Versuchen Sie, Gemeinsamkeiten in den Zahlwörtern zu ergründen und ordnen Sie ähnliche Fehlermuster einander zu. ";

    example.responses = [
        {
            id: '300',
            category: example.possibleCategories[0],
            text: "Korrekt! Analog zur Zahlwortbildung ab ‚Dreizehn‘ wurde auch hier der Einer fälschlicherweise zuerst versprachlicht.",
        },
        {
            id: '012',
            text: einsStelle + zweiZehner + erneut,
        },
        {
            id: '021',
            text: zweiStelle + einsZehner + erneut,
        },
        {
            id: '030',
            category: example.possibleCategories[1],
            text: "Genau! Die Zahlwortbildung wurde trotz Stellenübergangs nach demselben Schema fortgesetzt, ohne zu bündeln.",
        },
        {
            id: '102',
            text: einsEiner + zweiZehner + erneut,
        },
        {
            id: '111',
            text: einsEiner + einsStelle + einsZehner + erneut,
        },
        {
            id: '120',
            text: einsEiner + zweiStelle + erneut,
        },
        {
            id: '201',
            text: zweiEiner + einsZehner + erneut,
        },
        {
            id: '210',
            text: zweiEiner + einsStelle + erneut,
        },
        {
            id: '003',
            category: example.possibleCategories[2],
            text: "Richtig! Die Zahlwortbildung bei Glatten Zehnern ab ‚Vierzig‘ wurde hier übergeneralisiert und auch auf andere Zahlwörter übertragen, die im Deutschen jedoch anders realisiert werden.",
        },
    ]

    return example;
}

export default createObject();