function createObject() {
  let example = {
    id: "LBST_ZV_Pfade_ZA",
    workOrder: `Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.`,
    image: require("@/assets/zv/Pfade/zahlaspekte/01.png"),
    imageSizePercent: 60,
    mediaDescription: "Max bearbeitet die folgende Aufgabe:",
    pupilName: "Max",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question:
            "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/02.png"),
              solutionImage: require("@/assets/zv/Pfade/zahlaspekte/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/04.png"),
              solutionImage: require("@/assets/zv/Pfade/zahlaspekte/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/06.png"),
              solutionImage: require("@/assets/zv/Pfade/zahlaspekte/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/08.png"),
              solutionImage: require("@/assets/zv/Pfade/zahlaspekte/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/zv/Pfade/zahlaspekte/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 =
    "Diagnoseaufgabe 1 zeigt, Max bereits die Zahlwortreihe bis zur Sieben beherrscht. Beim Zählen ordnet er je einem Element ein Zahlwort zu, allerdings kann er noch nicht anhand des letztgenannten Zahlwortes die Anzahl der Plättchen angeben. ";

  const DA_2 =
    "Diagnoseaufgabe 2 lässt erkennen, dass Max durch die bildliche Anordnung der Plättchen in Form des Zahlsymbols „3“ dazu angeregt wird, die Anzahl der Plättchen ebenfalls mit drei anzugeben. Ein kardinales Mengenverständnis ist somit nicht vollständig ausgeprägt. ";

  const DA_3 =
    "Diagnoseaufgabe 3 zeigt, dass Max in einer unstrukturierten Menge an Plättchen deren Anzahl nicht simultan erfassen kann. Auch gelingt es ihm nicht, durch zählende Strategien die Anzahl zu ermitteln. ";

  const DA_4 =
    "Diagnoseaufgabe 4 lässt erkennen, dass Max die drei Ziffernkarten in der korrekten Reihenfolge ordnen kann, was auf ein ausgeprägtes ordinales Zahlverständnis schließen lässt. Hierbei kann Max sogar flexibel bei Werten anders als 1 beginnen. ";

  const FA_1 =
    "Förderaufgabe 1 spricht vor Allem den ordinalen Zahlaspekt an. Die Bearbeitung von Max legt hingegen nahe, dass sein kardinales Zahlverständnis noch nicht ausgeprägt ist. Somit ist die Aufgabe nicht geeignet, um auf Grundlage der Diagnose passend zu fördern. ";

  const FA_2 =
    "Förderaufgabe 2 versucht, Zahlsymbole und entsprechende kardinale Darstellungsweisen miteinander in Beziehung zu setzen. Die Zahlen werden hiermit aus der konkreten, starren Zahlenfolge gelöst und für sich stehend betrachtet.  ";

  const FA_3 =
    "Förderaufgabe 3 versucht, die Strategien der Anzahlermittlung zu versprachlichen und diese Strategie argumentativ zu untermauern. Vor dem Hintergrund der Diagnose ist eine zählende Anzahlermittlung anzunehmen. ";

  const FA_4 =
    "Förderaufgabe 4 versucht, Max‘ kardinales Zahlverständnis durch Verknüpfung mit Materialien aus der Lebensrealität zu fördern. Max wird zudem aufgefordert, seine Vorgehensweise argumentativ zu untermauern. Allerdings ist hier nicht davon auszugehen, dass Max neue Erkenntnisse gewinnen kann, da kein Bezug zur Ausgangsaufgabe hergestellt werden kann. ";

  const PASSUNG_SEHR_GUT = `
  Diese Förderaufgabe passt sehr gut zur Diagnose.<br />`;
  const PASSUNG_GUT = `
  Diese Förderaufgabe passt ganz gut zur Diagnose.<br />`;
  const PASSUNG_SCHLECHT = `
  Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.<br />`;

  const PASSUNG_SEHR_SCHLECHT = `
  Diese Aufgabe hätten wir nicht gewählt.<br />`;

  example.responses = [
    {
      id: "112",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_1,
    },
    {
      id: "113",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_1,
    },
    {
      id: "114",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_1,
    },
    {
      id: "123",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_1,
    },
    {
      id: "124",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_1,
    },
    {
      id: "134",
      text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_1,
    },
    {
      id: "212",
      text:
        PASSUNG_SEHR_GUT +
        DA_1 +
        DA_2 +
        FA_2 +
        "Max gewinnt die Erkenntnis, dass die Strukturierung der Plättchen deren Anzahl nicht beeinflusst.",
    },
    {
      id: "213",
      text:
        PASSUNG_SEHR_GUT +
        DA_1 +
        DA_3 +
        FA_2 +
        "Max gewinnt die Erkenntnis, dass die Strukturierung der Plättchen deren Anzahl nicht beeinflusst.",
    },
    {
      id: "214",
      text:
        PASSUNG_SEHR_GUT +
        DA_1 +
        DA_4 +
        FA_2 +
        "Da sein ordinales Zahlverständnis ausgeprägt scheint, kann in der Förderung zunächst der Fokus auf den kardinalen Zahlaspekt gelegt werden.",
    },
    {
      id: "223",
      text:
        PASSUNG_GUT +
        DA_2 +
        DA_3 +
        FA_2 +
        "Max gewinnt die Erkenntnis, dass die Strukturierung der Plättchen deren Anzahl nicht beeinflusst. Allerdings wird kein Rückbezug auf Diagnoseaufgabe 3 möglich, in der Max durch die Anordnung der Plättchen eine andere Anzahl angegeben hatte.",
    },
    {
      id: "224",
      text:
        PASSUNG_GUT +
        DA_2 +
        DA_4 +
        FA_2 +
        "Allerdings sollte in der Förderung die simultane Anzahlerfassung stärker in den Blick genommen werden, da diese in den Diagnoseaufgaben nicht gelungen ist.",
    },
    {
      id: "234",
      text:
        PASSUNG_GUT +
        DA_3 +
        DA_4 +
        FA_2 +
        "Allerdings sollte in der Förderung die simultane Anzahlerfassung stärker in den Blick genommen werden, da diese in der dritten Diagnoseaufgaben nicht gelungen ist.",
    },
    {
      id: "312",
      text:
        PASSUNG_SEHR_GUT +
        DA_1 +
        "Diese Kompetenz wird in der Förderaufgabe aufgegriffen. " +
        DA_2 +
        FA_3,
    },
    {
      id: "313",
      text: PASSUNG_GUT + DA_1 + DA_3 + FA_3,
    },
    {
      id: "314",
      text: PASSUNG_GUT + DA_1 + DA_4 + FA_3,
    },
    {
      id: "323",
      text:
        PASSUNG_GUT +
        DA_2 +
        DA_3 +
        FA_3 +
        "Diese Aufgabe greift jedoch nicht auf, dass Max in Diagnoseaufgabe 2 durch die Strukturierung der Plättchen eine andere Anzahl angegeben hat.",
    },
    {
      id: "324",
      text: PASSUNG_GUT + DA_2 + DA_4 + FA_3,
    },
    {
      id: "334",
      text: PASSUNG_GUT + DA_3 + DA_4 + FA_3,
    },
    {
      id: "412",
      text: PASSUNG_SCHLECHT + DA_1 + DA_2 + FA_4,
    },
    {
      id: "413",
      text: PASSUNG_SCHLECHT + DA_1 + DA_3 + FA_4,
    },
    {
      id: "414",
      text: PASSUNG_SCHLECHT + DA_1 + DA_4 + FA_4,
    },
    {
      id: "423",
      text: PASSUNG_SCHLECHT + DA_2 + DA_3 + FA_4,
    },
    {
      id: "424",
      text: PASSUNG_SCHLECHT + DA_2 + DA_4 + FA_4,
    },
    {
      id: "434",
      text: PASSUNG_SCHLECHT + DA_3 + DA_4 + FA_4,
    },
  ];
  return example;
}

export default createObject();
