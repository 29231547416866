<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";

export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Zahlverständnis",
          to: "/zahlverstaendnis",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/zahlverstaendnis/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Grundvorstellung von Zahlen",
          to: "/zahlverstaendnis/hintergrundwissen/grundvorstellungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Darstellungen vernetzen",
          to: "/zahlverstaendnis/hintergrundwissen/darstellungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Zusammenhänge zwischen Zahlen",
          to: "/zahlverstaendnis/hintergrundwissen/zusammenhaenge",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/zahlverstaendnis/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/zahlverstaendnis/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/zahlverstaendnis/check",
        },
      ],
    };
  },
};
</script>
