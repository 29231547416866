function createObject() {
  let example = {
    id: "LBST_ZV_Stempeln_ZV",
    workOrder: `Betrachte, wie Jette die folgende Aufgabe löst: `,
    image: require("@/assets/zv/Stempeln/ZV.png"),
    elements: [
      [
        {
          question: "Verfügt Jette über ein kardinales Zahlverständnis?",
          answers: [
            {
              correct: true,
              text: "Ja",
              response:
                "Richtig! „Vier Plättchen sind blau“ deutet darauf hin, dass Jette ein Verständnis für die Mächtigkeit von Mengen besitzt und diese im Sinne des kardinalen Zahlaspektes angeben kann.",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "Diese Einschätzung teilen wir nicht. „ Vier Plättchen sind blau“ weist deutlich auf ein kardinales Zahlverständnis hin. Die Anzahl der Plättchen mit der Eigenschaft „blau“ wird hier kardinal angegeben.",
            },
            {
              correct: false,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "Diese Einschätzung teilen wir nicht. „Vier Plättchen sind blau“ weist deutlich auf ein kardinales Zahlverständnis hin. Die Anzahl der Plättchen mit der Eigenschaft „blau“ wird hier kardinal angegeben.",
            },
          ],
        },
      ],
      [
        {
          question: "Verfügt Jette über ein ordinales Zahlverständnis?",
          answers: [
            {
              correct: true,
              text: "Ja",
              response:
                "Diese Einschätzung ist korrekt! Jette kann sowohl im Sinne der Zählzahl die Elemente aus einer Zahlenfolge angeben und diese auf das Muster beziehen („Es sind eins, zwei, drei, vier blaue Plättchen“), als auch die Position eines Elementes in einer geordneten Reihe beschreiben (Ordnungszahl, „Das neunte Plättchen ist blau“).",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "Diese Diagnose ist nicht korrekt. Jette kann sowohl im Sinne der Zählzahl die Elemente aus einer Zahlenfolge angeben und diese auf das Muster beziehen („Es sind eins, zwei, drei, vier blaue Plättchen“), als auch die Position eines Elementes in einer geordneten Reihe beschreiben (Ordnungszahl, „Das neunte Plättchen ist blau“).",
            },
            {
              correct: false,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "Diese Diagnose ist nicht korrekt. Jette kann sowohl im Sinne der Zählzahl die Elemente aus einer Zahlenfolge angeben und diese auf das Muster beziehen („Es sind eins, zwei, drei, vier blaue Plättchen“), als auch die Position eines Elementes in einer geordneten Reihe beschreiben (Ordnungszahl, „Das neunte Plättchen ist blau“).",
            },
          ],
        },
      ],
      [
        {
          question:
            "Verfügt Jette über ein Verständnis von Teil-Ganzes-Beziehungen?",
          answers: [
            {
              correct: true,
              text: "Ja",
              response:
                "Diese Diagnose scheint korrekt. „Die roten und blauen Plättchen ergeben zusammen zwölf“ drückt ein Verständnis davon aus, dass die roten und blauen Plättchen die Gesamtheit aller Plättchen ausmachen. Das Zahlentripel aus dem Ganzen und seinen Teilen hat Jette somit bereits identifiziert.",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "Falsch: Es ist davon auszugehen, dass Jette ein Teil-Ganzes-Verständnis besitzt. „Die roten und blauen Plättchen ergeben zusammen zwölf“ drückt ein Verständnis davon aus, dass die roten und blauen Plättchen die Gesamtheit aller Plättchen ausmachen. Das Zahlentripel aus dem Ganzen und seinen Teilen hat Jette somit bereits identifiziert.",
            },
            {
              correct: false,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "Falsch: Es ist davon auszugehen, dass Jette ein Teil-Ganzes-Verständnis besitzt. „Die roten und blauen Plättchen ergeben zusammen zwölf“ drückt ein Verständnis davon aus, dass die roten und blauen Plättchen die Gesamtheit aller Plättchen ausmachen. Das Zahlentripel aus dem Ganzen und seinen Teilen hat Jette somit bereits identifiziert.",
            },
          ],
        },
      ],
      [
        {
          question: "Verfügt Jette über die Fähigkeit, asynchron zu zählen?",
          answers: [
            {
              correct: true,
              text: "Ja",
              response:
                "Ein asynchrones Zählverständnis ist vorhanden. In der Aussage „Es sind eins, zwei, drei, vier blaue Plättchen“ beginnt Jette bei der Startzahl eins. Sie nutzt das Zählen, um einzelne Objekte (in diesem Fall die blauen Plättchen) zu zählen.",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "Jette kann bereits asynchron zählen. In der Aussage „Es sind eins, zwei, drei, vier blaue Plättchen“ beginnt Jette bei der Startzahl eins. Sie nutzt das Zählen, um einzelne Objekte (in diesem Fall die blauen Plättchen) zu zählen.",
            },
            {
              correct: false,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "Jette kann bereits asynchron zählen. In der Aussage „Es sind eins, zwei, drei, vier blaue Plättchen“ beginnt Jette bei der Startzahl eins. Sie nutzt das Zählen, um einzelne Objekte (in diesem Fall die blauen Plättchen) zu zählen.",
            },
          ],
        },
      ],
      [
        {
          question:
            "Verfügt Jette über die Fähigkeit, resultativ/abkürzend zu zählen?",
          answers: [
            {
              correct: true,
              text: "Ja",
              response:
                "Davon ist auszugehen. Jette zählt die roten Plättchen, die aufgrund des Musters in Zweiergruppen angeordnet sind, in diesen Gruppen und kann sich somit vom Zählen einzelner Objekte lösen. Damit weist die ein Verständnis für die Zweier-Reihe auf und kann die geordnete Darstellung für das verkürzte Zählen nutzen.",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "„Es sind zwei, vier, sechs, acht rote Plättchen“ weist auf die Fähigkeit des resultativen Zählens hin. Jette zählt die roten Plättchen, die aufgrund des Musters in Zweiergruppen angeordnet sind, in diesen Gruppen und kann sich somit vom Zählen einzelner Objekte lösen. Damit weist die ein Verständnis für die Zweier-Reihe auf und kann die geordnete Darstellung für das verkürzte Zählen nutzen.",
            },
            {
              correct: false,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "„Es sind zwei, vier, sechs, acht rote Plättchen“ weist auf die Fähigkeit des resultativen Zählens hin. Jette zählt die roten Plättchen, die aufgrund des Musters in Zweiergruppen angeordnet sind, in diesen Gruppen und kann sich somit vom Zählen einzelner Objekte lösen. Damit weist die ein Verständnis für die Zweier-Reihe auf und kann die geordnete Darstellung für das verkürzte Zählen nutzen.",
            },
          ],
        },
      ],
      [
        {
          question: "Kann Jette Anzahlen vergleichen?",
          answers: [
            {
              correct: false,
              text: "Ja",
              response:
                "Diese Einschätzung teilen wir nicht. Für den Vergleich von Zahlen werden entweder beide Mengen im Sinne des ordinalen Zahlaspektes abgezählt und die Zahlwörter werden verglichen, oder jedem Element der einen Menge wird eines der anderen Menge zugeordnet. Zu beiden Strategien finden sich keine Aussagen.",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "Diese Einschätzung teilen wir nicht. Für den Vergleich von Zahlen werden entweder beide Mengen im Sinne des ordinalen Zahlaspektes abgezählt und die Zahlwörter werden verglichen, oder jedem Element der einen Menge wird eines der anderen Menge zugeordnet. Zu beiden Strategien finden sich keine Aussagen, die auf ein Vorhandensein oder Fehlen der Fähigkeit, Anzahlen zu vergleichen, hindeuten.",
            },
            {
              correct: true,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "Diese Einschätzung teilen wir. Für den Vergleich von Zahlen werden entweder beide Mengen im Sinne des ordinalen Zahlaspektes abgezählt und die Zahlwörter werden verglichen, oder jedem Element der einen Menge wird eines der anderen Menge zugeordnet. Zu beiden Strategien finden sich keine Aussagen.",
            },
          ],
        },
      ],
      [
        {
          question: "Kann Jette ordinale Beziehungen ergründen?",
          answers: [
            {
              correct: true,
              text: "Ja",
              response:
                "Korrekt! Die Aussage „Das nächste Plättchen ist ebenfalls rot“ weist darauf hin, dass Jette aufgrund der Position des Plättchens in der Rangfolge die entsprechende Farbe zuordnen kann. „Jedes dritte Plättchen ist blau“ drückt dieses Verständnis des dargestellten Musters ebenfalls aus.",
            },
            {
              correct: false,
              text: "Nein",
              response:
                "Diese Diagnose ist nicht korrekt. Die Aussage „Das nächste Plättchen ist ebenfalls rot“ weist darauf hin, dass Jette aufgrund der Position des Plättchens in der Rangfolge die entsprechende Farbe zuordnen kann. „Jedes dritte Plättchen ist blau“ drückt dieses Verständnis des dargestellten Musters ebenfalls aus.",
            },
            {
              correct: false,
              text: "Kann anhand dieses Dokumentes nicht diagnostiziert werden",
              response:
                "Eine Diagnose dieser Kompetenz ist möglich. Die Aussage „Das nächste Plättchen ist ebenfalls rot“ weist darauf hin, dass Jette aufgrund der Position des Plättchens in der Rangfolge die entsprechende Farbe zuordnen kann. „Jedes dritte Plättchen ist blau“ drückt dieses Verständnis des dargestellten Musters ebenfalls aus.",
            },
          ],
        },
      ],
      [
        {
          question:
            "Wie beurteilen Sie Jettes Zahlverständnis vor dem Hintergrund dieser Diagnose?",
          answers: [
            {
              correct: true,
              text: "Jette verfügt über ein ausgeprägtes Zahlverständnis",
              response:
                "Vor dem Hintergrund der Diagnose lässt sich Jettes Zahlverständnis als ausgeprägt einschätzen. Sie verfügt über ein ordinales und kardinales Zahlverständnis, kann ordinale und kardinale Muster und Beziehungen ergründen und nutzt ihre flexiblen Zählfähigkeiten zur Anzahlermittlung.",
            },
            {
              correct: false,
              text:
                "Jettes Zahlverständnis ist grundlegend vorhanden, weitere gezielte Förderung ist jedoch noch notwendig, um es zu vertiefen.",
              response:
                "Jettes Zahlverständnis ist in fast allen Bereichen bereits sehr ausgeprägt. Sie verfügt über ordinale und kardinale Zahlvorstellungen, kann flexibel zählen und auch Zahlbeziehungen und Muster ergründen. Keine der Aussagen deutet auf ein fehlerhaftes Zahlverständnis hin. Lediglich zum Anzahlvergleich finden sich keine passenden Aussagen, die diese Kompetenz ausdrücken oder widerlegen.",
            },
            {
              correct: false,
              text: "Ein Zahlverständnis muss Jette erst noch aufbauen.",
              response:
                "Jettes Zahlverständnis ist in fast allen Bereichen bereits sehr ausgeprägt. Sie verfügt über ordinale und kardinale Zahlvorstellungen, kann flexibel zählen und auch Zahlbeziehungen und Muster ergründen. Keine der Aussagen deutet auf ein fehlerhaftes Zahlverständnis hin. Lediglich zum Anzahlvergleich finden sich keine passenden Aussagen, die diese Kompetenz ausdrücken oder widerlegen.",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
