<style scoped>
.no-uppercase {
  text-transform: none;
}
#app .selected {
  background-color: #477B7E !important;
  color: white;
}

.correct {
  background-color: #4caf50 !important;
  opacity: 0.8;
  color: white;
}

.wrong {
  background-color: #477B7E !important;
  color: white;
}

.lowerPadding {
  /* if we animate v-card-actions with v-expand-transition we animation is laggy and with padding enabled it looks unbalanced  */
  padding-top: 0;
  padding-bottom: 0;
}

.icon {
  color: #477B7E !important;
}

.iconDisabled {
  color: rgba(0, 0, 0, 0.12) !important;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.12);
}

.removePadding {
  padding-right: 0px !important;
}

.answer {
  width: 100%;
}
</style>
<template>
  <v-card outlined :class="{ disabled: disabled }">
    <v-card-text>
      <span v-html="questionAnswers.question"></span>
      <v-btn icon @click="toggleShowAnswersLocal">
        <v-icon class="icon" :class="{ iconDisabled: disabled }">{{
          showAnswersLocal ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
    </v-card-text>

    <v-card-actions class="lowerPadding">
      <v-expand-transition>
        <v-row v-show="showAnswersLocal" style="width: 100%;">
          <v-col
            ref="cardColumn"
            v-for="(diagnostic,
            diagnostic_index) in questionAnswers.diagnostics"
            :key="diagnostic_index"
            :cols="6"
            style="position: relative"
          >
            <resize-observer @notify="handleResize" />
            <vue-flipcard
              ref="flipcard"
              disable
              :width="flipcardWidth"
              :height="flipcardHeight"
            >
              <template slot="front">
                <v-card
                  @click="selectDiagnostic(diagnostic, diagnostic_index)"
                  style="display:flex; flex-direction: column; height: 100%"
                  :class="{
                    selected: isDiagnosticSelected(diagnostic),
                  }"
                  :disabled="allSelectedNotIncluded(diagnostic) || disabled"
                >
                  <v-card-text
                    v-if="diagnostic.taskText"
                    :class="{
                      selected: isDiagnosticSelected(diagnostic),
                    }"
                  >
                    {{ diagnostic.taskText }}
                  </v-card-text>
                  <div
                    style="max-height: 100%; height: 100%; overflow:auto; max-width: 100%"
                  >
                    <AppExpandableImage
                      v-if="diagnostic.taskImage"
                      :src="diagnostic.taskImage"
                      :parentId="parentId"
                      :elementId="
                        'Ebene: ' +
                          'Diagnose' +
                          ' Diagnoseaufgabe: ' +
                          (1 + diagnostic_index)
                      "
                    />
                  </div>
                  <v-card-actions style="height: auto;">
                    <v-btn block :disabled="disableAllAnswers">{{
                      isDiagnosticSelected(diagnostic)
                        ? questionAnswers.typeText +
                          " " +
                          (diagnostic_index + 1) +
                          " abwählen"
                        : questionAnswers.typeText +
                          " " +
                          (diagnostic_index + 1) +
                          " auswählen"
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
              <template slot="back">
                <v-card
                  style="display:flex; flex-direction: column; height: 100%"
                  :class="{
                    selected: isDiagnosticSelected(diagnostic),
                  }"
                  :disabled="allSelectedNotIncluded(diagnostic)"
                >
                  <v-card-text
                    v-if="diagnostic.solutionText"
                    :class="{
                      selected: isDiagnosticSelected(diagnostic),
                    }"
                  >
                    {{ diagnostic.solutionText }}
                  </v-card-text>
                  <div
                    style="max-height: 100%; height: 100%; overflow:auto; max-width: 100%"
                  >
                    <AppExpandableImage
                      v-if="diagnostic.solutionImage"
                      :src="diagnostic.solutionImage"
                      :parentId="parentId"
                      :elementId="
                        'Ebene: ' +
                          'Diagnose' +
                          ' Diagnoseaufgabe: ' +
                          (1 + diagnostic_index)
                      "
                    />
                  </div>
                </v-card>
              </template>
            </vue-flipcard>
          </v-col>
          <!-- response box
          <v-col v-if="showResponseLocal" cols="12">
            <AppAlertTooltip
              :textHtml="selectedAnswer.response"
              :additionalInformationHtml="
                selectedAnswer.additionalExplanationResponse
              "
              :type="selectedAnswer.correct ? 'success' : 'info'"
              :parentId="parentId"
            />
          </v-col> -->
        </v-row>
      </v-expand-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
// import AppAlertTooltip from "@/common/AppAlertTooltip";
import AppExpandableImage from "@/common/AppExpandableImage";
// import LernbausteinStempelnElementAdditionalInfo from "./LernbausteinStempelnElementAdditionalInfo.vue";
import AppHelper from "@/common/AppHelper";

export default {
  components: {
    // AppAlertTooltip,
    AppExpandableImage,
    // LernbausteinStempelnElementAdditionalInfo,
  },
  props: {
    questionAnswers: {
      type: Object,
      required: true,
    },
    stage: { type: Number, required: true },
    disabled: { type: Boolean, required: false },
    disableAllAnswers: { type: Boolean, required: false, default: false },
    showAnswers: { type: Boolean, default: false },
    parentId: { String, required: true },
    questionIndex: { String, required: true },
  },
  watch: {
    showAnswers: function(val) {
      this.showAnswersLocal = val;
    },
  },
  data: function() {
    return {
      selectedDiagnostics: [],
      selectedDiagnosticsIndex: [],
      selectedAnswer: {},
      selectedAnswerIndex: -1,
      showResponseLocal: false,
      answerSelectedEmitted: false,
      showAnswersEvaluated: false,
      showAnswersLocal: this.showAnswers,
      flipcardWidth: 300,
      flipcardHeight: 300,
    };
  },

  mounted() {
    this.handleResize({
      width: this.$refs.cardColumn[0].clientWidth,
      height: this.$refs.cardColumn[0].clientHeight,
    });
  },

  methods: {
    restoreState: function(restoredState, currentStage) {
      // if we have two stages or more, the second stage isn't triggered the right way, so we have to set the size by hand
      // if (this.flipcardWidth == 300) {
      //   this.handleResize({
      //     width: this.$refs.cardColumn[0].clientWidth,
      //     height: this.$refs.cardColumn[0].clientHeight,
      //   });
      // }
      this.flipcardWidth = restoredState.flipcardWidth;
      this.flipcardHeight = restoredState.flipcardHeight;

      this.selectedAnswerIndex = restoredState.selectedAnswerIndex;
      // the selectAnswer method changes the behaviour regarding if the feedback should be displayed, so we have to set it later than the select answer call
      this.showResponseLocal = restoredState.showResponseLocal;
      this.showAnswersEvaluated = restoredState.showAnswersEvaluated;
      this.showAnswersLocal = restoredState.showAnswersLocal;

      if (this.selectedAnswerIndex != -1) {
        // if we have an answer selected
        // this.answerSelectedEmitted = restoredState.answerSelectedEmitted; // Don't Do that! We have to act like this was the first time we selected the answer so the selectAnswer method works as intended
        this.selectedAnswer = this.questionAnswers.answers[
          restoredState.selectedAnswerIndex
        ];
        // this.selectAnswer(this.selectedAnswer, this.selectedAnswerIndex);
        let restoreState = true;
        this.$emit("answerSelected", this._self, restoreState);
        this.answerSelectedEmitted = true;
      }
      this.selectedDiagnosticsIndex = restoredState.selectedDiagnosticsIndex;
      if (this.selectedDiagnosticsIndex.length > 0) {
        for (let diagnosticIndex of this.selectedDiagnosticsIndex) {
          this.selectedDiagnostics.push(
            this.questionAnswers.diagnostics[diagnosticIndex - 1]
          );
        }
        if (currentStage > this.stage) {
          this.flipCards();
        }
        let restoreState = true;
        this.$emit("answerSelected", this._self, restoreState);
      }
      // this.selectedDiagnostics = restoredState.selectedDiagnostics;
    },

    selectDiagnostic: function(diagnostic, diagnostic_index) {
      if (!this.disableAllAnswers) {
        // only allow to select a diagnostic if not all answers are disabled
        // we need the function to disable all answer, because in the förder-ebene the cards wan't flip to we have to deactivate the v-card onClick and the button
        if (this.selectedDiagnostics.includes(diagnostic)) {
          this.selectedDiagnostics.splice(
            this.selectedDiagnostics.indexOf(diagnostic),
            1
          );
          this.selectedDiagnosticsIndex.splice(
            this.selectedDiagnosticsIndex.indexOf(diagnostic.id),
            1
          );
          this.$emit("answerDeselected", this._self);
          AppHelper.trackMatomoEvent(
            this,
            "LBSTPfade",
            "id:39; Ebene: " +
              (1 + this.stage) +
              " Frage: " +
              (1 + this.questionIndex) +
              " Antwort: " +
              (1 + diagnostic_index) +
              " abgewählt;",
            this.parentId
          );
        } else {
          if (
            this.selectedDiagnostics.length >= this.questionAnswers.selectCount
          ) {
            // NOP
          } else {
            this.selectedDiagnostics.push(diagnostic);
            this.selectedDiagnosticsIndex.push(diagnostic.id);
            this.$emit("answerSelected", this._self);
            AppHelper.trackMatomoEvent(
              this,
              "LBSTPfade",
              "id:39; Ebene: " +
                (1 + this.stage) +
                " Frage: " +
                (1 + this.questionIndex) +
                " Antwort: " +
                (1 + diagnostic_index) +
                " ausgewählt;",
              this.parentId
            );
          }
        }
      }
    },

    isDiagnosticSelected: function(diagnostic) {
      return this.selectedDiagnosticsIndex.includes(diagnostic.id);
    },

    checkAndShowResponse: function(trackMatomoEvents) {
      this.showResponseLocal = true;
      this.showAnswersLocal = true;
      this.showAnswersEvaluated = true;

      if (trackMatomoEvents) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:48; Ebene: " +
            (1 + this.stage) +
            " Frage: " +
            (1 + this.questionIndex) +
            " korrekt gelöst: " +
            this.selectedAnswer.correct +
            ";",
          this.parentId
        );
      }

      return this.selectedAnswer;
    },

    hideResponse: function() {
      this.showResponseLocal = false;
    },

    reset: function() {
      this.selectedAnswer = {};
      this.showResponseLocal = false;
      this.answerSelectedEmitted = false;
      this.showAnswersLocal = this.showAnswers;
      this.selectedAnswerIndex = -1;
      this.flipCards(true);
      this.selectedDiagnostics.splice(0); // empty array
      this.selectedDiagnosticsIndex.splice(0); // empty array
    },

    toggleShowAnswersLocal: function() {
      this.showAnswersLocal = !this.showAnswersLocal;
      AppHelper.trackMatomoEvent(
        this,
        "LBSTPfade",
        "id:47; Ebene: " +
          (1 + this.stage) +
          " deaktiviert: " +
          this.disabled +
          " Frage: " +
          (1 + this.questionIndex) +
          " Aufklappzustand von: " +
          !this.showAnswersLocal +
          " zu: " +
          this.showAnswersLocal +
          ";",
        this.parentId
      );
    },

    flipCards: function(reset) {
      // for (let flipcard of this.$refs.flipcard) {
      //   flipcard;
      // }
      if (this.stage == 0) {
        // :( ugly i know ... only flip cards of the diagnostic stage ... not the förder-ebene =/
        for (let diagnostic of this.selectedDiagnostics) {
          let flipcard = this.$refs.flipcard[diagnostic.id - 1];
          if (reset && flipcard.back) {
            flipcard.flip();
          } else if (!reset) {
            flipcard.flip();
          }
          // this.$refs.flipcard[diagnostic.id - 1].flip();
        }
        // this.$refs.flipcard[answer_index].flip();
      }
    },

    handleResize: function({ height, width }) {
      this.flipcardHeight = height - 24; //padding
      this.flipcardWidth = width - 24; // padding
    },

    allSelectedNotIncluded: function(diagnostic) {
      if (this.selectedDiagnostics.length >= this.questionAnswers.selectCount) {
        return !this.selectedDiagnostics.includes(diagnostic);
      }
      return false;
    },

    getSelectedIDs: function() {
      let id = "";
      for (let diagnostic of this.selectedDiagnostics) {
        if (id === "") {
          id += diagnostic.id;
        } else if (id < diagnostic.id) {
          id += diagnostic.id;
        } else {
          let oldId = id;
          id = diagnostic.id + oldId;
        }
      }
      return id;
    },
  },
};
</script>
