<style scoped>
.no-uppercase {
  text-transform: none;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.workOrder {
  font-size: 16px;
  background-color: #f5f5f5;
}
</style>

<template>
  <div class="lbst-border" :id="id">
    <v-row dense>
      <v-col cols="12">
        <v-row
          no-gutters
          style="flex-wrap: nowrap"
          class="justify-space-between"
        >
          <!-- workOrder -->
          <v-card outlined class="flex-grow-1">
            <v-card-text class="workOrder" v-html="workOrder"></v-card-text>
          </v-card>

          <!-- Info-Dialog -->
          <v-card outlined>
            <v-row no-gutters align="center" style="height: 100%">
              <AppDialogInfo
                title="Video-Anleitung für das interaktive Element"
                :elementId="this.id"
              >
                <video
                  preload="auto"
                  controls
                  width="60%"
                  style="margin-left: 20%"
                  @play="videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')"
                  @fullscreenchange="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                  @webkitfullscreenchange="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                  @mozfullscreenchange="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                  @msfullscreenchange="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                  @ended="videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')"
                  @pause="videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')"
                  @seeking="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                  @enterpictureinpicture="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                  @leavepictureinpicture="
                    videoPlayerEvent($event, 'Hilfevideo_LBST_Pfade.mp4')
                  "
                >
                  <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
                  <source
                    src="@/assets/hilfevideos/Hilfevideo_LBST_Pfade.mp4"
                    type="video/mp4"
                  />
                </video>
              </AppDialogInfo>
            </v-row>
          </v-card>
        </v-row>
      </v-col>

      <!-- image -->
      <v-col v-if="this.image" cols="12">
        <v-card outlined>
          <v-card-text class="text-center" v-if="this.mediaDescription">{{
            this.mediaDescription
          }}</v-card-text>

          <v-row justify="center">
            <v-col :style="'max-width: ' + imageSizePercent + '%'">
              <AppExpandableImage
                :src="this.image"
                contain
                :parentId="id"
                elementId="PfadeDokument"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-if="this.audio" cols="12">
        <v-card outlined style="position: sticky; top: 10px">
          <v-card-text class="text-center" v-if="this.mediaDescription">{{
            this.mediaDescription
          }}</v-card-text>

          <!-- https://github.com/wilsonwu/vuetify-audio -->
          <vuetify-audio
            ref="audioPlayer"
            :file="this.audio"
            color="primary"
            flat
            :ended="audioEnded"
            @click.native="audioPlayerClicked"
          ></vuetify-audio>
        </v-card>
      </v-col>

      <v-col cols="12">
        <div
          v-for="(stage, stage_index) in elements"
          :key="'stage' + stage_index"
        >
          <v-card
            outlined
            style="margin-bottom: 30px"
            :class="{ disabled: disableStage(stage_index) }"
          >
            <v-card-text>
              <LernbausteinPfadeElement
                v-for="(element, question_index) in stage"
                :key="'stage' + stage_index + 'question' + question_index"
                :ref="'stage' + stage_index"
                :questionAnswers="element"
                :stage="stage_index"
                :disabled="disableStage(stage_index)"
                :disableAllAnswers="currentStage > stage_index"
                :showAnswers="!disableStage(stage_index)"
                @answerSelected="checkallAnswerselectedForStage"
                @answerChanged="questionAnswerChanged"
                @answerDeselected="answerDeselected"
                style="margin-bottom: 10px"
                class="pa-2"
                :parentId="id"
                :questionIndex="question_index"
              />
              <v-row>
                <v-col cols="12">
                  <AppButtonCheck
                    :disabled="
                      stageState[stage_index]
                        ? !(
                            stageState[stage_index][
                              'allAnswersGivenPerStage'
                            ] === true
                          ) || currentStage > stage_index
                        : true
                    "
                    large
                    block
                    color="primary"
                    @click="showAnswersForAnsweredQuestions(stage_index, true)"
                    :text="generateCheckButtonText(stage_index)"
                  ></AppButtonCheck>
                </v-col>
                <v-col
                  v-if="
                    stageState[stage_index]
                      ? stageState[stage_index][
                          'allAnswersGivenPerStageCorrectResponse'
                        ]
                      : false
                  "
                >
                  <AppAlertTooltip
                    v-if="
                      stageState[stage_index]
                        ? stageState[stage_index][
                            'allAnswersGivenPerStageCorrectResponse'
                          ]
                        : false
                    "
                    :textHtml="
                      stageState[stage_index]
                        ? stageState[stage_index][
                            'allAnswersGivenPerStageCorrectResponse'
                          ].response
                        : ''
                    "
                    :additionalInformationHtml="
                      stageState[stage_index]
                        ? stageState[stage_index][
                            'allAnswersGivenPerStageCorrectResponse'
                          ].additionalExplanationResponse
                        : ''
                    "
                    :type="'info'"
                    :parentId="id"
                    :expandable="false"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-row no-gutters>
          <AppButtonReset
            @click="reset()"
            style="margin-top: 8px"
          ></AppButtonReset>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppButtonCheck from "@/common/AppButtonCheck";
import AppButtonReset from "@/common/AppButtonReset";
import AppAlertTooltip from "@/common/AppAlertTooltip";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppDialogInfo from "@/common/AppDialogInfo";
import LernbausteinPfadeElement from "./LernbausteinPfadeElement.vue";

import AppHelper from "@/common/AppHelper";

export default {
  name: "LernbausteinStempeln",
  components: {
    AppButtonReset,
    AppButtonCheck,
    AppAlertTooltip,
    AppExpandableImage,
    LernbausteinPfadeElement,
    VuetifyAudio: () => import("vuetify-audio"),
    AppDialogInfo,
  },
  mounted: function() {
    this.restoreState();
  },
  props: {
    /* unique id for the store */
    id: { String, required: true },

    /* The work order that should be displayed */
    workOrder: { String, required: true },

    /* The additional description of the image/audio */
    mediaDescription: { String, required: false },

    /* The audio that should be played */
    audio: { Object, required: false },

    /* The image that should be displayed */
    image: { Object, required: false },

    /* The image that should be displayed */
    imageSizePercent: { Number, required: false },

    /* Elements we receive that we should display */
    elements: { Array, required: true },

    responses: { Array, required: true },

    /* The name of the pupil, used in the button text */
    pupilName: { String, required: false },
  },
  data: () => ({
    /*
      Position in array = stage
      {
          allAnswersGivenPerStage: [] // if true on position 0, the stage 0 has all answers given
          refsQuestionsAnswered: [] // refs to all questions that were answered
          allAnswersGivenPerStageCorrectResponse: {}

      }
    */
    stageState: [], // a map would be better, but in vue2 maps are not reactive

    currentStage: 0, // displays the current stage
  }),

  methods: {
    restoreState: function() {
      const storeId = "LBSTPfade" + this.id;

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storeId]
        : false;

      if (restoredState) {
        this.currentStage = restoredState.data.currentStage;

        for (let ref in this.$refs) {
          if (ref.includes("stage")) {
            for (let question in this.$refs[ref]) {
              this.$refs[ref][question].restoreState(
                restoredState[ref][question],
                restoredState.data.currentStage
              );
            }
          }
        }

        for (let stageIndex in this.stageState) {
          this.stageState[stageIndex].allAnswersGivenPerStage =
            restoredState.data.stageState[stageIndex].allAnswersGivenPerStage;
          this.stageState[stageIndex].allAnswersGivenPerStageCorrectResponse =
            restoredState.data.stageState[
              stageIndex
            ].allAnswersGivenPerStageCorrectResponse;
        }
      }

      if (!this.$store.hasModule(["nested", storeId])) {
        this.$store.registerModule(["nested", storeId], {
          namespaced: true,
          state: {
            data: {},
            stage0: [],
            stage1: [],
            stage2: [],
            stage3: [],
            stage4: [],
            stage5: [],
            stage6: [],
            stage7: [],
            stage8: [],
            stage9: [],
          },
          mutations: {
            data(state, payload) {
              // state.data = payload;
              let clonedStageState = payload.stageState.map((object) => ({
                ...object,
              }));
              for (let stageIndex in clonedStageState) {
                delete clonedStageState[stageIndex].refsQuestionsAnswered;
              }
              state.data = {
                currentStage: payload.currentStage,
                stageState: clonedStageState,
              };
              state.stage0.splice(0); // empty array
              state.stage1.splice(0); // empty array
              state.stage2.splice(0); // empty array
              state.stage3.splice(0); // empty array
              state.stage4.splice(0); // empty array
              state.stage5.splice(0); // empty array
              state.stage6.splice(0); // empty array
              state.stage7.splice(0); // empty array
              state.stage8.splice(0); // empty array
              state.stage9.splice(0); // empty array
            },
            dataQuestions(state, payload) {
              state[payload.id].push(payload);
            },
          },
        });
        // VERY IMPORTANT! If missing this state is not registered in the nested store state and will not be send to the server if another component updates it state
        this.saveStateLocal(); // save the current state, so even if we don't change anything the last state will be send to the server
      }
    },
    saveStateLocal: function() {
      const storeId = "LBSTPfade" + this.id;
      this.$store.commit("nested/" + storeId + "/data", { ...this._data });
      for (let ref in this.$refs) {
        if (ref.includes("stage")) {
          for (let question in this.$refs[ref]) {
            this.$store.commit("nested/" + storeId + "/dataQuestions", {
              ...this.$refs[ref][question]._data,
              id: ref,
            });
          }
        }
      }
    },

    saveState: function() {
      this.saveStateLocal();
      // send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            // if we have an error, we don't save the state
            // console.log(err);
            // network error can be invalid token, be aware!
          });
      } catch {
        // if we have an error, we don't save the state
        // console.log("down " + err);
      }
    },
    /* resets the lbst to start over again */
    reset: function() {
      for (let element in this.$refs) {
        for (let i = 0; i < this.$refs[element].length; i++) {
          this.$refs[element][i].reset();
        }
      }
      this.stageState.splice(0); // empty array
      this.currentStage = 0;
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.stop();
      }
      this.saveState();
      AppHelper.trackMatomoEvent(this, "LBSTPfade", "id:28; Reset;", this.id);
      this.$scrollTo(this.$el);
    },

    /* the check button should only be enabled if all questions per stage were answered */
    disableCheckButton: function(stage_index) {
      if (this.stageState[stage_index]) {
        return this.stageState[stage_index]["allAnswersGivenPerStage"];
      }
      return true;
    },

    disableStage: function(stage_index) {
      return stage_index > this.currentStage;
    },

    questionAnswerChanged: function(question_ref) {
      let newStageState = this.copyStageStateInNewObject(question_ref.stage);
      newStageState["allAnswersGivenPerStageCorrectResponse"] = null;

      this.$set(this.stageState, question_ref.stage, newStageState);

      // also we have to enable the local response for any question given
      for (
        let i = 0;
        i < this.stageState[question_ref.stage]["refsQuestionsAnswered"].length;
        i++
      ) {
        if (
          this.stageState[question_ref.stage]["refsQuestionsAnswered"][i] !==
          question_ref
        ) {
          // if we are not the question that triggered this event
          if (
            this.stageState[question_ref.stage]["refsQuestionsAnswered"][i]
              .showAnswersEvaluated
          ) {
            // if we already show the answer evaluated, we open the local response
            this.stageState[question_ref.stage]["refsQuestionsAnswered"][
              i
            ].checkAndShowResponse(true);
          }
        }
      }

      this.saveState();
    },

    answerDeselected: function(question_ref) {
      if (this.stageState[question_ref.stage]) {
        /* we already have an element for the question stage */
        let newStageState = this.copyStageStateInNewObject(question_ref.stage);
        let selectCount =
          newStageState["refsQuestionsAnswered"][0].questionAnswers.selectCount;
        newStageState["refsQuestionsAnswered"].slice(
          newStageState["refsQuestionsAnswered"].indexOf(question_ref),
          1
        );
        if (newStageState["allAnswersGivenPerStage"] === true) {
          newStageState["allAnswersGivenPerStage"] = selectCount - 1;
        } else {
          newStageState["allAnswersGivenPerStage"] -= 1;
        }
        this.$set(this.stageState, question_ref.stage, newStageState);
        this.saveState();
      }
    },

    checkallAnswerselectedForStage: function(question_ref, restoreState) {
      if (this.stageState[question_ref.stage]) {
        /* we already have an element for the question stage, so we can add */
        let newStageState = this.copyStageStateInNewObject(question_ref.stage);

        /* add question, questions will not be added more than once, because the answerSelected event in the question will only be fired once */
        newStageState["refsQuestionsAnswered"].push(question_ref);

        /* one additional answer was given */
        newStageState["allAnswersGivenPerStage"] += 1;

        if (
          newStageState["allAnswersGivenPerStage"] ==
          question_ref.questionAnswers.selectCount
          // this.elements[question_ref.stage].length
        ) {
          /* if all answers were answered that are available for this stage, we set the value to true */
          newStageState["allAnswersGivenPerStage"] = true;
        }

        this.$set(this.stageState, question_ref.stage, newStageState);
      } else {
        /* we have no element for the question stage, so we have to create the first one */
        let newStageState = {
          refsQuestionsAnswered: [question_ref],
          allAnswersGivenPerStage:
            1 == question_ref.questionAnswers.selectCount ? true : 1, // if we have only one question per stage, we can directly say allAnswersGivenPerStage is true
          allAnswersGivenPerStageCorrectResponse: null,
        };

        this.$set(this.stageState, question_ref.stage, newStageState);
      }
      if (!restoreState) {
        // if we restore the state we don't want to save the state again
        this.saveState();
      }
    },

    showAnswersForAnsweredQuestions: function(stage_index, trackMatomoEvents) {
      if (stage_index == 0) {
        // we are in the diagnostic area, we want to flip the cards an enable the next stage which is the 'förder'-area
        this.stageState[stage_index]["refsQuestionsAnswered"][0].flipCards();
        /* increase the current stage */
        let newStageState = this.copyStageStateInNewObject(stage_index);
        /* collect responses from questions and add them to one */
        if (this.pupilName != null) {
        newStageState["allAnswersGivenPerStageCorrectResponse"] = {
          response:
            "Im oberen Bereich sehen Sie jetzt die gelösten Diagnoseaufgaben. Bitte wählen Sie im unteren Bereich die geeignete Förderaufgabe aus!",
        };
        }
        this.$set(this.stageState, stage_index, newStageState);
        this.currentStage =
          this.currentStage <= stage_index
            ? stage_index + 1
            : this.currentStage;
      } else if (stage_index == 1) {
        // förder-ebene
        // currently we don't have a solution for the förder-ebene, so we don't want to flip the cards ...
        // this.stageState[stage_index]["refsQuestionsAnswered"][0].flipCards();
        let newStageState = this.copyStageStateInNewObject(stage_index);
        let responseID = ""; // first digit is the selected task from the förder-ebene, second and third digit are the selected tasks from the diagnostic stage
        responseID += this.stageState[1][
          "refsQuestionsAnswered"
        ][0].getSelectedIDs(); // förder-ebene
        responseID += this.stageState[0][
          "refsQuestionsAnswered"
        ][0].getSelectedIDs(); // diagnostic stage
        let allAnswersCorrectResponse = this.getResponse(responseID);
        let allAnswersCorrectAdditionalExplanationResponse = "";

        /* collect responses from questions and add them to one */
        newStageState["allAnswersGivenPerStageCorrectResponse"] = {
          response: allAnswersCorrectResponse,
          additionalExplanationResponse: allAnswersCorrectAdditionalExplanationResponse,
        };

        this.$set(this.stageState, stage_index, newStageState);
        this.currentStage =
          this.currentStage <= stage_index
            ? stage_index + 1
            : this.currentStage;
      }

      if (trackMatomoEvents) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:29; Überprüfen betätigt für Ebene: " + (1 + stage_index) + ";",
          this.id
        );
      }

      // let allAnswersCorrect = true;
      // let allAnswersCorrectResponse = "";
      // let allAnswersCorrectAdditionalExplanationResponse = "";

      // let firstWrongAnsweredQuestion = null;

      // for (
      //   let i = 0;
      //   i < this.stageState[stage_index]["refsQuestionsAnswered"].length;
      //   i++
      // ) {
      //   let questionAnswer = this.stageState[stage_index][
      //     "refsQuestionsAnswered"
      //   ][i].checkAndShowResponse(trackMatomoEvents);
      //   allAnswersCorrectResponse += questionAnswer.response + " ";
      //   allAnswersCorrectAdditionalExplanationResponse +=
      //     questionAnswer.additionalExplanationResponse + " ";
      //   if (!questionAnswer.correct) {
      //     allAnswersCorrect = false;
      //     if (firstWrongAnsweredQuestion == null) {
      //       firstWrongAnsweredQuestion = this.stageState[stage_index][
      //         "refsQuestionsAnswered"
      //       ][i];
      //     }
      //     // we can't stop here, because all answers should be checked
      //   }
      // }
      // if (allAnswersCorrect) {
      //   let newStageState = this.copyStageStateInNewObject(stage_index);

      //   /* collect responses from questions and add them to one */
      //   newStageState["allAnswersGivenPerStageCorrectResponse"] = {
      //     response: allAnswersCorrectResponse,
      //     additionalExplanationResponse: allAnswersCorrectAdditionalExplanationResponse,
      //   };

      //   this.$set(this.stageState, stage_index, newStageState);

      //   /* remove responses for individual questions for the sake of a global response */
      //   for (
      //     let i = 0;
      //     i < this.stageState[stage_index]["refsQuestionsAnswered"].length;
      //     i++
      //   ) {
      //     this.stageState[stage_index]["refsQuestionsAnswered"][
      //       i
      //     ].hideResponse();
      //   }

      if (trackMatomoEvents) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:30; Ebene: " + (this.currentStage + 1) + " korrekt gelöst;",
          this.id
        );
      }

      /* increase the current stage */
      this.currentStage =
        this.currentStage <= stage_index ? stage_index + 1 : this.currentStage;

      if (this.elements.length == this.currentStage) {
        // the last stage was correct
        if (trackMatomoEvents) {
          AppHelper.trackMatomoEvent(
            this,
            "LBSTPfade",
            "id:31; Letzte Ebene korekt gelöst. LBST erfolgreich abgeschlossen.;",
            this.id
          );
        }
      }
      // }

      // if (firstWrongAnsweredQuestion != null) {
      //   this.$scrollTo(firstWrongAnsweredQuestion.$el);
      // }

      if (trackMatomoEvents) {
        // if we want to track the matomo events, we are in normal mode, so we want to save the state
        this.saveState();
      }
    },

    /* helper method to copy the stage state into a new object, maybe change some values, this is necessary because of vues reactiveness considering arrays */
    copyStageStateInNewObject: function(index) {
      let newStageState = {
        refsQuestionsAnswered: this.stageState[index]["refsQuestionsAnswered"],
        allAnswersGivenPerStage: this.stageState[index][
          "allAnswersGivenPerStage"
        ],
        allAnswersGivenPerStageCorrectResponse: this.stageState[index][
          "allAnswersGivenPerStageCorrectResponse"
        ],
      };
      return newStageState;
    },

    audioEnded: function() {
      AppHelper.trackMatomoEvent(
        this,
        "LBSTPfade",
        "id:33; Audio komplett abgespielt;",
        this.id
      );
    },

    audioPlayerClicked: function(event) {
      if (
        this.$refs.audioPlayer.firstPlay &&
        event.srcElement.classList.contains("mdi-play")
      ) {
        // the first playback can cause an unwated pause event, so we have to check it this way
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:32; Audio first play currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            ";",
          this.id
        );
      } else if (event.srcElement.classList.contains("mdi-stop")) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:34; Audio stop currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            ";",
          this.id
        );
      } else if (event.srcElement.classList.contains("mdi-play")) {
        // der button hat noch das andere logo, es handelt sich aber um pause
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:35; Audio pause currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            ";",
          this.id
        );
      } else if (event.srcElement.classList.contains("mdi-pause")) {
        // der button hat noch das andere logo, es handelt sich aber um play
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:36; Audio play currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            ";",
          this.id
        );
      } else if (event.srcElement.classList.contains("mdi-volume-mute")) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:37; Audio mute currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            ";",
          this.id
        );
      } else if (event.srcElement.classList.contains("mdi-volume-high")) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:38; Audio unmute currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            ";",
          this.id
        );
      } else if (
        event.srcElement.classList.contains("v-progress-linear__buffer") || // if we click on the lighter blue area of the line
        event.srcElement.classList.contains("v-progress-linear__determinate") // if we click on the darker blue area of the line (already passed)
      ) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTPfade",
          "id:49; Audio seeking currentTime: " +
            this.$refs.audioPlayer.currentTime +
            " duration: " +
            this.$refs.audioPlayer.duration +
            " set to percentage: " +
            this.$refs.audioPlayer.percentage +
            ";",
          this.id
        );
      }
    },
    generateCheckButtonText: function(stage_index) {
      if (this.stageState[stage_index]) {
        if (this.stageState[stage_index]["allAnswersGivenPerStage"] === true) {
          if (this.pupilName != null) {
            if (stage_index == 0) {
              return "Diagnoseaufgaben von " + this.pupilName + " lösen lassen";
            } else {
              return "Feedback zur Auswahl der Förderaufgabe";
            }
          }else{return "Auswahl bestätigen"}
        } else {
          return (
            "Ausgewählt: " +
            this.stageState[stage_index]["allAnswersGivenPerStage"] +
            "/" +
            this.stageState[stage_index]["refsQuestionsAnswered"][0]
              .questionAnswers.selectCount
          );
        }
      } else {
        return "Ausgewählt: 0 / " + this.elements[stage_index][0].selectCount;
      }
      // stageState[stage_index]
      //                   ? stageState[stage_index]['allAnswersGivenPerStage'] === true)
      //                   : true
      //                 'Ausgewählt: ' +
      //                   (stageState[stage_index]
      //                     ? stageState[stage_index]['allAnswersGivenPerStage']
      //                     : '0') +
      //                   '/2'
      //               "
    },
    getResponse: function(key) {
      for (let i = 0; i < this.responses.length; i++) {
        if (this.responses[i].id == key) {
          return this.responses[i].text;
        }
      }
    },

    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>
