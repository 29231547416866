<template>
  <div>
    <h2 class="faledia-caption">Diagnose & Förderung</h2>
    <p>
      Auch wenn das Hintergrundwissen essentiell ist, ist es nicht weniger
      wichtig zu trainieren, dieses Wissen auf konkrete Dokumente von Kindern
      anwenden zu können. Vor allem ist es dabei zentral erfassen zu können, in
      welchen Bereichen das Zahlverständnis bereits gut ausgebildet ist und wo
      eventuell Probleme vorliegen.
    </p>
    <p>
      Im Hintergrundteil haben Sie erfahren, dass der Aufbau einer tragfähigen
      Zahlvorstellung eine zentrale Aufgabe des Anfangsunterrichts im Fach
      Mathematik ist. Bei der folgenden Aufgabe soll das Zahlverständnis der
      Schülerin Jette in Bezug auf die Facetten des Hintergrundwissens
      untersucht werden:
    </p>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln.id"
        :workOrder="LBSTStempeln.workOrder"
        :elements="LBSTStempeln.elements"
        :image="LBSTStempeln.image"
      >
        <template v-slot:alternative-content-0>
          <p>
            „Vier Plättchen sind blau“ deutet darauf hin, dass Jette über ein
            kardinales Zahlverständnis verfügt. Diese Aussage bezieht sich auf
            die Mächtigkeit der Menge.
          </p>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Jette verfügt über ein ordinales Zahlverständnis. „Es sind eins,
            zwei, drei, vier blaue Plättchen“ deutet darauf hin, dass Jette sich
            auf Elemente aus einer Zahlenfolge beziehen kann und somit den
            Zählzahlaspekt beherrscht.
          </p>
          <p>
            Auch den Ordnungszahlaspekt beherrscht sie: Dieser bezieht sich auf
            die Position eines Elements in einer geordneten Reihe. „Das neunte
            Plättchen ist blau“ drückt aus, dass Jette dies verinnerlicht hat.
          </p>
        </template>
        <template v-slot:alternative-content-2>
          <p>
            „Die roten und blauen Plättchen ergeben zusammen zwölf“ drückt ein
            Verständnis für die Teil-Ganzes-Beziehungen aus. Dieses beschreibt
            ein Zahlentripel bestehend aus dem Ganzen und zwei Teilen. Die
            Gesamtheit aller Plättchen beträgt hier zwölf, die roten und blauen
            Plättchen entsprechen jeweils einem Teil.
          </p>
        </template>
        <template v-slot:alternative-content-3>
          <p>
            In der Aussage „Es sind eins, zwei, drei, vier blaue Plättchen“ wird
            deutlich, dass Jette bereits die Zahlwörter unterscheiden kann und
            sie nicht wie beim verbalen Zählen als ein zusammenhängendes Wort
            nutzt. Sie beherrscht also das asynchrone Zählen, da sie die
            einzelnen Zahlwörter nutzen kann, um die Elemente (hier die blauen
            Plättchen) zu zählen.
          </p>
        </template>
        <template v-slot:alternative-content-4>
          <p>
            Jette ordnet die roten Plättchen jeweils in Zweiergruppen, da diese
            Gruppen jeweils von einem blauen Plättchen getrennt werden. Sie kann
            somit bereits in Zweierschritten zählen, was ausdrückt, dass sie
            abkürzend zählen kann.
          </p>
        </template>
        <template v-slot:alternative-content-5>
          <p>
            Keine der Aussagen drückt explizit aus, ob die Anzahlen vergleichen
            kann oder nicht. Daher kann nicht beurteilt werden, ob Jette über
            diese Kompetenz bereits verfügt. Eine weitere Diagnose wird somit
            notwendig.
          </p>
          <p>
            Für den Vergleich von Zahlen werden entweder beide Mengen im Sinne
            des ordinalen Zahlaspektes abgezählt und die Zahlwörter werden
            verglichen, oder jedem Element der einen Menge wird eines der
            anderen Menge zugeordnet. Zu beiden Strategien finden sich keine
            Aussagen.
          </p>
        </template>
        <template v-slot:alternative-content-6>
          <p>
            Jette hat die Struktur des Musters verstanden und kann in
            Abhängigkeit von der Position die Farbe des Plättchens angeben. Dies
            äußert sich in der Aussage „Das nächste Plättchen ist ebenfalls
            rot.“
          </p>
          <p>
            Außerdem kann sie die allgemeine Struktur beschreiben, indem sie
            sagt „Jedes dritte Plättchen ist blau.“ Hier äußert sich ein
            Verständnis für die Ordnungszahl, einem Teilaspekt des ordinalen
            Zahlverständnises.
          </p>
        </template>
        <template v-slot:alternative-content-7>
          <p>
            Jettes Zahlverständnis kann insgesamt als ausgeprägt bezeichnet
            werden.
          </p>
          <p>
            Sie verfügt sowohl über ein ordinales als auch ein kardinales
            Zahlverständnis und kann dieses nutzen, um Muster und Beziehungen zu
            ergründen und diese zu beschreiben.
          </p>
          <p>
            Auch kann sie ihre Zählkompetenzen nutzen, um die mathematische
            Struktur weiter zu untersuchen und Aussagen über die Anzahlen der
            Plättchen zu treffen. Hierbei greift sie außerdem auf ein
            Verständnis für Teil-Ganzes-Beziehungen zurück.
          </p>
          <p>
            Lediglich bezüglich des Zahlvergleichs kann keine Aussage getroffen
            werden.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>

    <p>
      Der Aufbau tragfähiger Zahlvorstellungen steht in engem Zusammenhang mit
      der Entwicklung des Zählverständnisses. Hierzu ist neben der Fähigkeit,
      Mengen abzuzählen, auch die Beherrschung der Zahlwortreihe notwendig
      (Hasemann & Gasteiger, 2014). Die Lernenden müssen zunächst die Worte, die
      den jeweiligen Elementen zugeordnet werden, erlernen und in der
      entsprechenden Reihenfolge begreifen, bevor flexible Zählvorgänge möglich
      werden.
    </p>
    <p>
      Da die Zahlwortbildung im Deutschen einige Unregelmäßigkeiten aufweist,
      beispielsweise indem die Einer vor den Zehnern versprachlicht – nicht aber
      verschriftlicht - werden, sind Zählfehler in der Schuleingangsphase keine
      Seltenheit. Betrachten Sie in der folgenden Aufgabe die Zählfehler und
      ordnen Sie diese in Kategorien derselben Fehlerursache.
    </p>
    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen.id"
        :checkAfterHowManyItems="LBSTGruppen.checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen.checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen.showGroupNames"
        :workOrder="LBSTGruppen.workOrder"
        :possibleCategories="LBSTGruppen.possibleCategories"
        :elements="LBSTGruppen.elements"
        :responses="LBSTGruppen.responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      Übergeneralisierung zuerst versprachlichter Einer
                    </strong>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/01.png"></v-img>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/02.png"></v-img>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/03.png"></v-img>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Übergeneralisierung nach Stellenübergang
                    </strong>
                  </td>
                   <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/04.png"></v-img>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/05.png"></v-img>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/06.png"></v-img>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Übergeneralisierung der Zehnwebildung mit -zig
                    </strong>
                  </td>
                   <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/07.png"></v-img>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/08.png"></v-img>
                  </td>
                  <td>
                    <v-img src="@/assets/zv/Gruppen/zaehlfehler/09.png"></v-img>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>

    <p>
      Die Ursachen von Fehlern können sehr vielfältig sein. Das Erkennen dieser
      Ursachen bildet die Grundlage für eine individuelle und adaptive
      Förderung, durch welche individuelle Fehlvorstellungen aufgearbeitet und
      durch ein besseres Verständnis abgebaut werden können.
    </p>
    <p>
      Das passgenaue Zusammenspiel von Diagnostik und gezielter Förderung ist
      zentral für das gesamte unterrichtliche Geschehen. Im nachfolgenden
      Beispiel haben Sie nun die Gelegenheit zunächst eine Feindiagnostik auf
      Grundlage erster diagnostischer Erkenntnisse durchzuführen und im
      Anschluss daran eine geeignete Förderaufgabe auszuwählen.
    </p>
    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBSTPfade[0].id"
        :workOrder="LBSTPfade[0].workOrder"
        :image="LBSTPfade[0].image"
        :imageSizePercent="LBSTPfade[0].imageSizePercent"
        :mediaDescription="LBSTPfade[0].mediaDescription"
        :audio="LBSTPfade[0].audio"
        :elements="LBSTPfade[0].elements"
        :responses="LBSTPfade[0].responses"
        :pupilName="LBSTPfade[0].pupilName"
      />
    </div>

    <p>
      In der Lösung von Max wird deutlich, dass hier noch nicht alle Zahlaspekte
      ausgeprägt sind. Dies zu diagnostizieren und mit entsprechenden
      Förderaufgaben einen Aufbau zu unterstützen, ist vor allem in der
      Schuleingangsphase besonders wichtig.
    </p>

    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBSTPfade[1].id"
        :workOrder="LBSTPfade[1].workOrder"
        :image="LBSTPfade[1].image"
        :imageSizePercent="LBSTPfade[1].imageSizePercent"
        :mediaDescription="LBSTPfade[1].mediaDescription"
        :audio="LBSTPfade[1].audio"
        :elements="LBSTPfade[1].elements"
        :responses="LBSTPfade[1].responses"
        :pupilName="LBSTPfade[1].pupilName"
      />
    </div>

    <p>
      Auch Miras Lösung der Aufgabe <i>Zahlen unter der Lupe </i>verdeutlicht,
      wie viele unterschiedliche Ebenen die Kinder zu Beginn des
      Mathematikunterrichts miteinander verknüpfen lernen müssen. Dabei ist ein
      häufiger Darstellungswechsel wichtig, um zum einen diagnostizieren zu
      können, welche Darstellungsebenen noch schwerer fallen und zum anderen ist
      dies hilfreich bei dem Verknüpfen von Vorstellungen.
    </p>
    <p>
      Zusammenfassend zeigt sich also, dass der Aufbau des Zahlverständnisses
      eine zentrale Rolle im frühen Mathematikunterricht einnimmt.
    </p>

    <AppLiteraturZV />
    <AppBottomNavZV
      back="/zahlverstaendnis/uebergang"
      next="/zahlverstaendnis/check"
    />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
import AppLiteraturZV from "@/common/AppLiteraturZV";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";

import LBST_Stempeln_ZV from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Stempeln_ZV";
import LBST_Gruppen_ZF from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Gruppen_Zaehlfehler";
import LBST_Pfade_ZA from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Pfade_Zahlaspekte";
import LBST_Pfade_DV from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Pfade_Darstellungsvernetzung";

export default {
  components: {
    AppBottomNavZV,
    AppLiteraturZV,
    LernbausteinStempeln,
    LernbausteinGruppen,
    LernbausteinPfade,
  },
  data: () => ({
    LBSTGruppen: LBST_Gruppen_ZF,
    LBSTStempeln: LBST_Stempeln_ZV,
    LBSTPfade: [LBST_Pfade_ZA, LBST_Pfade_DV],
  }),
};
</script>
