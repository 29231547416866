var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{ disabled: _vm.disabled },attrs:{"outlined":""}},[_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.questionAnswers.question)}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.toggleShowAnswersLocal}},[_c('v-icon',{staticClass:"icon",class:{ iconDisabled: _vm.disabled }},[_vm._v(_vm._s(_vm.showAnswersLocal ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-card-actions',{staticClass:"lowerPadding"},[_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswersLocal),expression:"showAnswersLocal"}],staticStyle:{"width":"100%"}},_vm._l((_vm.questionAnswers.diagnostics),function(diagnostic,diagnostic_index){return _c('v-col',{key:diagnostic_index,ref:"cardColumn",refInFor:true,staticStyle:{"position":"relative"},attrs:{"cols":6}},[_c('resize-observer',{on:{"notify":_vm.handleResize}}),_c('vue-flipcard',{ref:"flipcard",refInFor:true,attrs:{"disable":"","width":_vm.flipcardWidth,"height":_vm.flipcardHeight}},[_c('template',{slot:"front"},[_c('v-card',{class:{
                  selected: _vm.isDiagnosticSelected(diagnostic),
                },staticStyle:{"display":"flex","flex-direction":"column","height":"100%"},attrs:{"disabled":_vm.allSelectedNotIncluded(diagnostic) || _vm.disabled},on:{"click":function($event){return _vm.selectDiagnostic(diagnostic, diagnostic_index)}}},[(diagnostic.taskText)?_c('v-card-text',{class:{
                    selected: _vm.isDiagnosticSelected(diagnostic),
                  }},[_vm._v(" "+_vm._s(diagnostic.taskText)+" ")]):_vm._e(),_c('div',{staticStyle:{"max-height":"100%","height":"100%","overflow":"auto","max-width":"100%"}},[(diagnostic.taskImage)?_c('AppExpandableImage',{attrs:{"src":diagnostic.taskImage,"parentId":_vm.parentId,"elementId":'Ebene: ' +
                        'Diagnose' +
                        ' Diagnoseaufgabe: ' +
                        (1 + diagnostic_index)}}):_vm._e()],1),_c('v-card-actions',{staticStyle:{"height":"auto"}},[_c('v-btn',{attrs:{"block":"","disabled":_vm.disableAllAnswers}},[_vm._v(_vm._s(_vm.isDiagnosticSelected(diagnostic) ? _vm.questionAnswers.typeText + " " + (diagnostic_index + 1) + " abwählen" : _vm.questionAnswers.typeText + " " + (diagnostic_index + 1) + " auswählen"))])],1)],1)],1),_c('template',{slot:"back"},[_c('v-card',{class:{
                  selected: _vm.isDiagnosticSelected(diagnostic),
                },staticStyle:{"display":"flex","flex-direction":"column","height":"100%"},attrs:{"disabled":_vm.allSelectedNotIncluded(diagnostic)}},[(diagnostic.solutionText)?_c('v-card-text',{class:{
                    selected: _vm.isDiagnosticSelected(diagnostic),
                  }},[_vm._v(" "+_vm._s(diagnostic.solutionText)+" ")]):_vm._e(),_c('div',{staticStyle:{"max-height":"100%","height":"100%","overflow":"auto","max-width":"100%"}},[(diagnostic.solutionImage)?_c('AppExpandableImage',{attrs:{"src":diagnostic.solutionImage,"parentId":_vm.parentId,"elementId":'Ebene: ' +
                        'Diagnose' +
                        ' Diagnoseaufgabe: ' +
                        (1 + diagnostic_index)}}):_vm._e()],1)],1)],1)],2)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }