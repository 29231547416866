<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>

<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Benacerraf, P. & Putnam, H. (1984):
                <i>Philosophy of Mathematics: Selected Readings.</i> Cambridge
                University Press. Online verfügbar unter
                https://books.google.de/books?id=cUSbAAAAQBAJ.
              </td>
            </tr>
            <tr>
              <td>
                Bönig, D.; Hering, J.; London, M.; Nührenbörger, M. & Thöne, B.
                (2017).
                <i
                  >Erzähl mal Mathe! Mathematiklernen im Kindergartenalltag und
                  am Schulanfang.</i
                >
                1. Auflage. Seelze: Klett Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                Fromme, M. (2017).<i>
                  Stellenwertverständnis im Zahlenraum bis 100: Theoretische und
                  empirische Analysen.</i
                >
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Fuson, K.C. (1988).
                <i>Children’s Counting and Concepts of Number. </i>New York:
                Springer-Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik M. (2007).
                <i
                  >Rechenschwäche verstehen – Kinder gezielt fördern: Ein
                  Leitfaden für die Unterrichtspraxis (1. Bis 4. Klasse).
                </i>
                Horneburg: Persen Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik, M. (2010a).
                <i
                  >Die Entwicklung von Lösungsstrategien zu den additiven
                  Grundaufgaben im Laufe des ersten Schuljahres. </i
                >http://othes.univie.ac.at/9155/1/2010-01-18_8302038.pdf.
                Zugriff am 15.11.2015.
              </td>
            </tr>
            <tr>
              <td>
                Gasteiger, H. (2011).
                <i
                  >Mathematisches Lernen von Anfang an. Kompetenzorientierte
                  Förderung im Übergang Kindertagesstätte - Grundschule.
                  Handreichung für das Programm SINUS an Grundschulen.</i
                >
                http://www.sinus-an-grundschulen.de/fileadmin/uploads/Material_aus_SGS/Handreichung_Gasteiger_Internet.pdf.
                Zugriff am 30.11.2015.
              </td>
            </tr>
            <tr>
              <td>
                Hasemann, K., & Gasteiger, H. (2014).
                <i>Anfangsunterricht Mathematik (3. Aufl.). </i> Berlin:
                Springer Spektrum, S. 118-145.
              </td>
            </tr>
            <tr>
              <td>
                Häsel-Weide, U. (2016).
                <i
                  >Vom Zählen zum Rechnen. Struktur-fokussierende Deutungen in
                  kooperativen Lernumgebungen.</i
                >
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Krauthausen, G. & Scherer, P. (2007).
                <i>Einführung in die Mathematikdidaktik. 3. Auflage. </i
                >München: Spektrum Akademischer Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Krauthausen, G. (2018),
                <i>Einführung in die Mathematikdidaktik. Grundschule.</i>
                Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Kuhnke, K. (2013).<i>
                  Vorgehensweisen von Grundschulkindern beim
                  Darstellungswechsel. Eine Untersuchung am Beispiel der
                  Multiplikation im 2. Schuljahr.
                </i>
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Mack, W. (2005).
                <i>Eins, zwei, drei, viele.</i>
                http://www.forschung-frankfurt.uni-frankfurt.de/36050383/14_eins_zwei____.pdf
                / Zugriff am 30.11.2015.
              </td>
            </tr>
            <tr>
              <td>
                Ministerium für Schule und Weiterbildung des Landes Nordrhein-
                Westfalen (Hrsg.) (2008).
                <i
                  >Richtlinien und Lehrpläne für die Grundschule in
                  Nordrhein-Westfalen.</i
                >
                Frechen: Ritterbach.
              </td>
            </tr>
            <tr>
              <td>
                Moser Opitz, E. (2007). Erstrechnen. In U. Heimlich & F. Wember
                (Hg.),
                <i
                  >Didaktik des Unterrichts im Förderschwerpunkt Lernen. Ein
                  Handbuch für Studium und Praxis. </i
                >(S. 253-265). Stuttgart: Kohlhammer.
              </td>
            </tr>
            <tr>
              <td>
                Resnick, L. B. (1983). A developmental theory of number
                understanding. In H. P. Ginsburg (Hg.),
                <i>The development of mathematical thinking </i>(S. 109-151).
                New York: Academic Press.
              </td>
            </tr>
            <tr>
              <td>
                Ruwisch, S. (2015).
                <i>
                  Wie die Zahlen im Kopf wirksam werden. Merkmale tragfähiger
                  Zahlvorstellungen. Grundschule Mathematik 44 (1. Quartal),
                  4-5.</i
                >
              </td>
            </tr>
            <tr>
              <td>
                Scherer, P. (1999).<i>
                  Produktives Lernen für Kinder mit Lernschwächen: Fördern durch
                  Fordern. </i
                >Leipzig: Ernst Klett Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Scherer, P. & Moser Opitz, E. (2010).<i>
                  Fördern im Mathematikunterricht der Primarstufe.</i
                >
                Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Schipper, W. (2008).
                <i
                  >Rechenstörungen als schulische Herausforderung. Handreichung
                  zur Förderung von Kindern mit besonderen Schwierigkeiten beim
                  Rechnen. </i
                >Ludwigsfelde-Struveshof: Landesinstitut für Schule und Medien
                Berlin-Brandenburg (LISUM).
                https://www.uni-bielefeld.de/idm/serv/handreichung-schipper.pdf.
                Zugriff am 15.11.2015.
              </td>
            </tr>
            <tr>
              <td>
                Schuler, S. (2013):<i>
                  Mathematische Bildung im Kindergarten in formal offenen
                  Situationen – eine Untersuchung am Beispiel von Spielen zum
                  Erwerb des Zahlbegriffs.</i
                >
                Münster: Waxmann.
              </td>
            </tr>
            <tr>
              <td>
                Schulz, A. (2014).
                <i
                  >Fachdidaktisches Wissen von Grundschullehrkräften. Diagnose
                  und Förderung bei besonderen Problemen beim Rechnenlernen.
                </i>
                Wiesbaden: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Selter, Ch. (1995). Zur Fiktivität der ‚Stunde Null‘ im
                arithmetischen Anfangsunterricht.<i
                  >Mathematische Unterrichtspraxis,</i
                >
                11-19. Resource document.
                http://math-www.upb.de/~hartmut/AndereTexte/Stunde_0.pdf [Abruf
                am 29.07.2011]
              </td>
            </tr>
            <tr>
              <td>
                Wartha, S. & Schulz, A. (2011).<i>
                  Aufbau von Grundvorstellungen (nicht nur) bei besonderen
                  Schwierigkeiten im Rechnen.</i
                >
                Kiel: IPN.
              </td>
            </tr>
            <tr>
              <td>
                Wittmann, E.C. & Müller, G. (2009).
                <i>Das Zahlenbuch. Handbuch zum Frühförderprogramm. </i
                >Stuttgart: Ernst Klett-Verlag.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturOV",
  components: {
    AppButtonCollapse,
  },
};
</script>
